import React, { useState, useEffect, useContext } from "react";
import nurseService from "../../../utils/api/v1/nurseService";
import timeBlockService from "../../../utils/api/v1/timeblockService";
import {
  groupTimeBlocks,
  groupBlockedTimeBlocks,
} from "../../../utils/timeblocks/group";
import { Link, navigate } from "gatsby";

// Contexts
import { FiltersContext } from "../../../components/Stores/FilterStore";

// typings
import {
  GroupedTimeBlocks,
  GroupedBlockedTimeBlocks,
} from "../../../utils/interfaces/Timeblock";

// components
import PrivateRoute from "../../../components/Authentication/PrivateRoute";
import LoadingError from "../../../components/Loaders/LoadingError";
import Flex from "../../../components/Containers/Flex";
import Bold from "../../../components/Typography/Bold";
import { ButtonGroup } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import DisplayTimeBlock from "../../../components/Timeblocks/DisplayTimeBlock";
import DisplayBlockedTimeBlock from "../../../components/Timeblocks/DisplayBlockedTimeBlock";
import NurseData from "../../../components/Nurses/NurseData";
import {
  SpacedPaper,
  SectionHeader,
} from "../../../components/Containers/SpacedPaper";
import { Nurse, Specialty } from "../../../utils/interfaces/Nurse";
import ActionReceiverComponent from "../../../components/Nurses/ActionReceiverComponent";

interface Props {
  id: string;
}

const NurseProfile = (props: Props): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>({});
  const [nurseData, setNurseData] = useState<Nurse | undefined>();
  const [activeTimeBlocks, setActiveTimeBlocks] = useState<GroupedTimeBlocks>(
    {}
  );
  const [labsAndNExams, setLabsAndNExams] = useState<Object>({});
  const [servicesCategories, setServicesCategories] = useState<Array<string>>(
    []
  );
  const [blockedTimeBlocks, setBlockedTimeBlocks] =
    useState<GroupedBlockedTimeBlocks>({});
  // @ts-expect-error Type 'Filters' must have a '[Symbol.iterator]()' method that returns an iterator.ts(2488)
  const [, filtersDispatch] = useContext(FiltersContext);

  const fetchData = async (): Promise<void> => {
    setLoading(true);
    try {
      const reqs = await Promise.all([
        nurseService.fetchNurseProfile(props.id),
        timeBlockService.fetchNurseTimeBlocks(props.id),
        nurseService.fetchLabsAndNExams(props.id),
        nurseService.fetchServicesCategories(props.id),
      ]);
      const data = reqs[0].data;
      data.specialties = data.specialties.map(
        (speciality: Specialty) => speciality.name
      );
      setNurseData(data);
      filtersDispatch({
        type: "UPDATE_NURSE",
        payload: reqs[0].data.id,
      });
      setActiveTimeBlocks(groupTimeBlocks(reqs[1].data.data.timeblocks));
      setBlockedTimeBlocks(
        groupBlockedTimeBlocks(
          reqs[1].data.data.blocked
        ) as GroupedBlockedTimeBlocks
      );
      setLabsAndNExams(reqs[2].data.data);
      setServicesCategories(reqs[3].data.data);
    } catch (err) {
      console.log(err);
      setError(err);
    }
    setLoading(false);
  };

  const updateComunas = (newValues: string[]) => {
    setNurseData((oldValues) => ({
      ...oldValues,
      comunas_available: newValues,
    }));
  };

  const updateSpecialties = (newValues: string[]) => {
    setNurseData((oldValues) => ({
      ...oldValues,
      specialties: newValues,
    }));
  };

  const handleActivate = async () => {
    setLoading(true);
    try {
      const data = {
        active: !nurseData.active,
      };
      await nurseService.activateNurse(nurseData.id, data);
      setNurseData((oldValues) => ({
        ...oldValues,
        active: !oldValues.active,
      }));
    } catch (err) {
      console.log(err);
      setError(err);
    }
    setLoading(false);
  };

  const updateNurse = async (): Promise<void> => {
    try {
      const data = {
        comunas: nurseData.comunas_available,
        fingerprint_available: nurseData.fingerprint_available,
        specialties: nurseData.specialties,
        fake: nurseData.fake,
      };
      const req = await nurseService.updateNurse(nurseData.id, data);
      const nurseInfo = req.data.data;
      nurseInfo.specialties = nurseInfo.specialties.map(
        (speciality: Specialty) => speciality.name
      );
      setNurseData(nurseInfo);
    } catch (err) {
      console.log(err);
      setError(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <PrivateRoute>
      <LoadingError loading={loading} error={error} />
      {!loading && nurseData && (
        <>
          <NurseData
            nurseData={nurseData}
            setNurseData={setNurseData}
            updateNurse={updateNurse}
            updateComunas={updateComunas}
            updateSpecialties={updateSpecialties}
            handleActivate={handleActivate}
            labsAndNExams={labsAndNExams}
            servicesCategories={servicesCategories}
          />
          <SpacedPaper variant="outlined">
            <Flex direction="column">
              <SectionHeader>
                <Bold>Horarios habilitados</Bold>
                <ButtonGroup variant="text">
                  <Button
                    color="primary"
                    onClick={() => navigate("/dashboard/")}
                  >
                    Ver agenda
                  </Button>
                  <Link
                    to={`/health-team/${nurseData.id}/schedule/`}
                    style={{ textDecoration: "none" }}
                  >
                    <Button color="primary">Editar</Button>
                  </Link>
                </ButtonGroup>
              </SectionHeader>
              <hr />
              <DisplayTimeBlock timeblocks={activeTimeBlocks} />
            </Flex>
          </SpacedPaper>
          <SpacedPaper variant="outlined">
            <Flex direction="column">
              <SectionHeader>
                <Bold>Horarios bloqueados</Bold>
                <Link
                  to={`/health-team/${nurseData.id}/block-schedule/`}
                  style={{ textDecoration: "none" }}
                >
                  <Button color="primary">Editar</Button>
                </Link>
              </SectionHeader>
              <hr />
              <DisplayBlockedTimeBlock timeblocks={blockedTimeBlocks} />
            </Flex>
          </SpacedPaper>
          <ActionReceiverComponent
            nurseData={nurseData}
            loading={loading}
            setError={setError}
          />
        </>
      )}
    </PrivateRoute>
  );
};

export default NurseProfile;
