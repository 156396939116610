import React, { useEffect, useState } from "react";
import styled from "styled-components";
import regionComuna from "../../utils/comunas/region_comunas.json";
import estadoMunicipio from "../../utils/comunas/estado_municipio.json";
import { navigate } from "gatsby";

// other files
import { COUNTRIES } from "../../utils/data/constants";

// components
import { SpacedPaper, SectionHeader } from "../Containers/SpacedPaper";
import Flex from "../Containers/Flex";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import Bold from "../Typography/Bold";
import { Button, FormControlLabel } from "@material-ui/core";
import { ButtonGroup } from "@material-ui/core";
import { MenuProps as MenuPropsType } from "@material-ui/core/Menu";
import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import CircularProgress from "@material-ui/core/CircularProgress";
import Swal from "sweetalert2";
import nurseService from "../../utils/api/v1/nurseService";
import { Nurse, Specialty } from "../../utils/interfaces/Nurse";
import { nurseAPI } from "../../utils/api/v2";

const BigAvatar = styled(Avatar)`
  width: 10rem;
  height: 10rem;
`;

const SpacedSpan = styled.span`
  padding: 0.5rem;
`;

const FlexContainer = styled(Flex)`
  overflow: auto;
  max-width: 400px;

  .MuiChip-root {
    margin: 0.25rem;
  }
`;

const UserDataContainer = styled.div`
  display: grid;
  grid-template-columns: 6rem 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
`;

const regiones = Object.keys(regionComuna);

const locationSelectProps: Partial<MenuPropsType> = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
};

interface Props {
  nurseData: Nurse;
  setNurseData: (nurseData: Nurse) => void;
  updateComunas: (newValues: string[]) => void;
  updateSpecialties: (newValues: string[]) => void;
  updateNurse: () => Promise<void>;
  handleActivate: () => void;
  labsAndNExams: Object;
  servicesCategories: Array<string>;
}

const NurseData = (props: Props): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [editable, setEditable] = useState<boolean>(false);
  const [nurseInitialData, setNurseInitialData] = useState<Nurse>();
  const [validPhotoUploaded, setValidPhotoUploaded] = useState<boolean>(false);
  const [selectedRegion, setSelectedRegion] = useState<string>(regiones[0]);
  const [country, setCountry] = useState<string>("Chile");
  const [specialtyOptions, setSpecialtyOptions] = useState<string[]>([]);
  const [nurseCommunes, setNurseCommunes] = useState<any[]>([]);
  const [comunasOptions, setComunasOptions] = useState<string[]>(
    regionComuna[regiones[0]]
  );

  const [files, setFiles] = useState([]);

  useEffect(() => {
    const fetchTerritories = async () => {
      try {
        const nurseTerritoriesRes = await nurseAPI.listTerritories(
          props.nurseData.id,
          country === "Chile" ? "cl" : "mx"
        );
        setNurseCommunes(nurseTerritoriesRes.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchTerritories();
  }, [country]);

  const listLabsAndNExams = Object.entries(props.labsAndNExams);
  const traductions = {
    blood: "Sangre",
    urine: "Orina",
    vaccine: "Vacuna",
    covid: "Covid",
    kine: "Kine",
    electrocardiogram: "ECG",
  };

  useEffect(() => {
    const fetchNurseSpecialties = async () => {
      const res = await nurseService.fetchNurseSpecialties();
      const specialties = res.data.map(
        (speciality: Specialty) => speciality.name
      );
      setSpecialtyOptions(specialties);
    };
    fetchNurseSpecialties();
    setNurseInitialData(props.nurseData);
  }, []);

  const updateNurse = async () => {
    setLoading(true);
    await props.updateNurse();
    setEditable(false);
    setLoading(false);
  };

  const handleChange = (event: any) => {
    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];

      // if file > 15MB, reject
      if (file.size > 15_000_000) {
        Swal.fire({
          icon: "error",
          title: "El archivo no debe pesar mas de 15MB",
        });
        setValidPhotoUploaded(false);
        return false;
      }
      // add file to files state
      setValidPhotoUploaded(true);
      setFiles((prevFiles) => {
        return [...prevFiles, file];
      });
    }
  };

  const renderSelectedOptions = (selected: string[] | string | any) => {
    return (
      <Flex wrap="wrap">
        {(selected as string[]).map((value) => (
          <Chip variant="outlined" color="primary" key={value} label={value} />
        ))}
      </Flex>
    );
  };

  const uploadProfilePic = async (): Promise<void> => {
    setLoading(true);
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });
    const uploaded = await nurseService.uploadProfilePicture(
      props.nurseData.id,
      formData
    );
    if (uploaded) {
      Swal.fire({
        icon: "success",
        title: "Foto de perfil cambiada",
      });
      setLoading(false);
      setEditable(false);
    } else {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "La foto no se pudo subir",
      });
    }
  };

  const handleActivate = async () => {
    const check = await Swal.fire({
      title: "¿Estás seguro?",
      icon: "warning",
      showCancelButton: true,
    });
    if (!check.isConfirmed) {
      return;
    }
    props.handleActivate();
  };

  const handleCancel = () => {
    props.setNurseData(nurseInitialData);
    setEditable(false);
  };

  const handleCountryChange = (event: any) => {
    setCountry(event.target.value);
    if (event.target.value === "Chile") {
      setComunasOptions(regionComuna[selectedRegion]);
    } else if (event.target.value === "México") {
      const municipalities = estadoMunicipio["Ciudad de México"]
        .concat(estadoMunicipio["Estado de México"])
        .concat(estadoMunicipio["Morales"]);
      setComunasOptions(municipalities.sort((a, b) => a.localeCompare(b)));
    }
  };

  const countrySelector = (): JSX.Element => {
    return (
      <FormControl fullWidth>
        <Select fullWidth value={country} onChange={handleCountryChange}>
          {COUNTRIES.map((name) => (
            <MenuItem value={name} key={name}>
              {name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>País</FormHelperText>
      </FormControl>
    );
  };

  const displayComunas = (): JSX.Element[] => {
    return nurseCommunes?.map(({ name }) => (
      <Chip variant="outlined" color="primary" key={name} label={name} />
    ));
  };

  const editableSelectors = (): JSX.Element => {
    return (
      <>
        {countrySelector()}
        <FormControl fullWidth>
          <Select
            fullWidth
            value={selectedRegion}
            disabled={country === "México"}
            onChange={(e) => {
              setSelectedRegion(e.target.value as string);
              setComunasOptions(regionComuna[e.target.value as string]);
            }}
          >
            {regiones.map((region: string, index: number) => (
              <MenuItem key={index} value={region}>
                {region}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>Región</FormHelperText>
        </FormControl>
        <FormControl fullWidth>
          <Select
            multiple
            fullWidth
            value={props.nurseData.comunas_available}
            onChange={(event) => {
              props.updateComunas(event.target.value as string[]);
            }}
            input={<Input id="select-multiple-chip" />}
            MenuProps={locationSelectProps}
            renderValue={renderSelectedOptions}
          >
            {comunasOptions.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>Comunas</FormHelperText>
        </FormControl>

        {/* specialties */}
        <FormControl fullWidth>
          <Select
            multiple
            fullWidth
            value={props.nurseData.specialties}
            onChange={(event) => {
              props.updateSpecialties(event.target.value as string[]);
            }}
            input={<Input id="select-multiple-chip" />}
            MenuProps={locationSelectProps}
            renderValue={renderSelectedOptions}
          >
            {specialtyOptions?.map((specialty) => (
              <MenuItem key={specialty} value={specialty}>
                {specialty}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>Especialidades</FormHelperText>
        </FormControl>
      </>
    );
  };

  return (
    <SpacedPaper variant="outlined">
      <Flex direction="column">
        <SectionHeader>
          <Bold>Datos personales</Bold>
          {editable ? (
            <ButtonGroup variant="text">
              <Button color="secondary" onClick={handleCancel}>
                Cancelar
              </Button>
              <Button color="primary" onClick={updateNurse}>
                Actualizar
              </Button>
            </ButtonGroup>
          ) : (
            <ButtonGroup variant="text">
              {props.nurseData.active ? (
                <Button color="secondary" onClick={handleActivate}>
                  Desactivar
                </Button>
              ) : (
                <Button color="primary" onClick={handleActivate}>
                  Activar
                </Button>
              )}
              <Button
                color="primary"
                onClick={() => {
                  setEditable(true);
                }}
              >
                Editar
              </Button>
              <Button
                color="primary"
                onClick={() =>
                  navigate(`/health-team/${props.nurseData.id}/exams/`, {
                    state: props.nurseData,
                  })
                }
              >
                Detalle exámenes
              </Button>
            </ButtonGroup>
          )}
        </SectionHeader>
        <hr />
        {loading ? (
          <Flex justify="center" align="center" padding="3rem">
            <CircularProgress />
          </Flex>
        ) : (
          <Flex padding="2rem" justify="space-between">
            <Flex direction="column" justify="center">
              {editable ? (
                <Flex direction="column">
                  <input type="file" accept="image/*" onChange={handleChange} />
                  <Button
                    style={{ marginTop: 10 }}
                    color="primary"
                    variant="contained"
                    disabled={!validPhotoUploaded}
                    onClick={() => {
                      uploadProfilePic();
                    }}
                  >
                    Subir Foto de Perfil
                  </Button>
                </Flex>
              ) : (
                <BigAvatar src={props.nurseData.profile_picture} />
              )}
            </Flex>
            <UserDataContainer>
              <Bold>Nombre: </Bold>
              {props.nurseData.names} {props.nurseData.last_names}
              <Bold>Email: </Bold>
              {props.nurseData.email}
              {props.nurseData.rut !== "" && <Bold>Rut: </Bold>}
              {props.nurseData.rut}
              <Bold>Teléfono: </Bold>
              {props.nurseData.phone}
            </UserDataContainer>
            <Flex direction="column" align="center">
              <FormControlLabel
                control={
                  <Switch
                    disabled={!editable}
                    name="¿Tiene huellero?"
                    color="primary"
                    checked={props.nurseData.fingerprint_available}
                    onChange={(e) => {
                      props.setNurseData({
                        ...props.nurseData,
                        fingerprint_available: e.target.checked,
                      });
                    }}
                  />
                }
                label="¿Tiene huellero?"
              />
              <FormControlLabel
                control={
                  <Switch
                    disabled={!editable}
                    name="Cuenta Falsa"
                    color="primary"
                    checked={props.nurseData.fake}
                    onChange={(e) => {
                      props.setNurseData({
                        ...props.nurseData,
                        fake: e.target.checked,
                      });
                    }}
                  />
                }
                label="Cuenta Falsa"
              />
              <Bold>Servicios:</Bold>
              <FlexContainer
                justify="center"
                align="center"
                padding="1rem 0rem"
                wrap="wrap"
              >
                {props.servicesCategories.map((category) => (
                  <Chip
                    variant="outlined"
                    color="primary"
                    key={category}
                    label={traductions[category]}
                  />
                ))}
              </FlexContainer>
              <Bold>Comunas:</Bold>
              <FlexContainer
                justify="center"
                align="center"
                padding="1rem 0rem"
                wrap="wrap"
              >
                {editable ? editableSelectors() : displayComunas()}
              </FlexContainer>

              {!editable && (
                <>
                  <Bold>Especialidades:</Bold>
                  {props.nurseData.specialties?.length == 0 &&
                    "No tiene especialidades"}
                  {props.nurseData.specialties?.length > 0 && (
                    <FlexContainer>
                      {props.nurseData.specialties.map((speciality: string) => (
                        <Chip
                          variant="outlined"
                          color="primary"
                          key={speciality}
                          label={speciality}
                        />
                      ))}
                    </FlexContainer>
                  )}
                </>
              )}
              <SpacedSpan>
                <Bold>Laboratorios:</Bold>
                {listLabsAndNExams.map((item) => (
                  <p>
                    {item[0]} ({item[1]})
                  </p>
                ))}
              </SpacedSpan>
            </Flex>
          </Flex>
        )}
      </Flex>
    </SpacedPaper>
  );
};

export default NurseData;
